var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}})],1),_c('datatable',{ref:"datatable",attrs:{"module":"operasional","title":_vm.title,"url":_vm.url,"columns":_vm.columns,"on-click-create":_vm.onClickCreate,"on-click-update":_vm.onClickUpdate,"on-click-delete":_vm.onClickDelete,"filter":_vm.filter,"disable-action":"","disable-search":"","disable-create":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-col',{attrs:{"md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)]},proxy:true},{key:"item.tanggal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.tanggal,"Do MMMM YYYY"))+" ")]}},{key:"item.jam",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.jam,"HH:mm"))+" ")]}},{key:"item.total_tiba",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.tiba) - parseInt(item.turun) + parseInt(item.naik) || 0)+" ")]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }