<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <v-row>
          <v-col cols="12" sm="6" md="4"> </v-col>
        </v-row>
        <datatable
          ref="datatable"
          module="operasional"
          :title="title"
          :url="url"
          :columns="columns"
          :on-click-create="onClickCreate"
          :on-click-update="onClickUpdate"
          :on-click-delete="onClickDelete"
          :filter="filter"
          disable-action
          disable-search
          disable-create
        >
          <template v-slot:action>
            <v-col md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Tanggal"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" no-title scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </template>
          <template v-slot:item.tanggal="{ item }">
            {{ item.tanggal | moment("Do MMMM YYYY") }}
          </template>
          <template v-slot:item.jam="{ item }">
            {{ item.jam | moment("HH:mm") }}
          </template>
          <template v-slot:item.total_tiba="{ item }">
            {{
              parseInt(item.tiba) -
                parseInt(item.turun) +
                parseInt(item.naik) || 0
            }}
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Datatable from "@/view/component/Datatable.vue";
import { clone } from "lodash";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Operasional Terminal", route: "" },
      {
        title: "Penumpang Berangkat",
      },
    ]);
  },
  data() {
    return {
      title: "Penumpang Berangkat",
      dates: [],
      url: "operasional/data-penumpang-berangkat",
      columns: [
        {
          text: "Terminal",
          value: "terminal",
          sortable: true,
          searchable: true,
        },
        {
          text: "Tanggal",
          value: "tanggal",
          sortable: true,
          searchable: true,
        },
        {
          text: "Jam",
          value: "jam",
          sortable: true,
          searchable: true,
        },
        {
          text: "No. Kendaraan",
          value: "no_kendaraan",
          sortable: true,
          searchable: true,
        },
        {
          text: "Jumlah Penumpang",
          value: "tiba",
          sortable: true,
          searchable: true,
        },
        {
          data: "naik",
        },
        {
          data: "turun",
        },
        // {
        //   data: "naik",
        // },
      ],
      filter: [
        {
          column: "tanggal",
          value: [
            moment().format("YYYY-MM-DD"),
            moment()
              .add(1, "d")
              .format("YYYY-MM-DD"),
          ],
          operator: "between",
        },
      ],
    };
  },
  methods: {
    onClickCreate() {
      this.$refs.form.open();
    },
    onClickUpdate(item) {
      this.$refs.form.open(item.id);
    },
    onClickDelete(item) {
      this.$refs.delete.open(item.id);
    },
    formSuccess() {
      this.$refs.datatable.getData();
    },
    deleteSuccess() {
      this.$refs.datatable.getData();
    },
    openUploadDialog() {
      this.$refs.formUpload.open();
    },
    getTipe(tipe) {
      return find(this.listTipe, { id: tipe }).text;
    },
  },
  components: {
    Datatable,
  },
  computed: {
    dateRangeText() {
      return (
        moment(this.dates[0]).format("Do MMM YYYY") +
        " s/d " +
        moment(this.dates[1]).format("Do MMM YYYY")
      );
    },
  },
  watch: {
    dates(val) {
      var newVal = clone(val);
      if (newVal.length == 2) {
        newVal[1] = moment(newVal[1])
          .add(1, "d")
          .format("YYYY-MM-DD");

        this.filter = [
          {
            column: "tanggal",
            value: newVal,
            operator: "between",
          },
        ];
        this.$refs.datatable.getData();
      }
    },
  },
};
</script>

<style></style>
